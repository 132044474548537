import classNames from 'classnames';
import { ForwardedRef, forwardRef, PropsWithChildren, ReactNode } from 'react';

type CardProps = PropsWithChildren<{
	className?: string;
	label?: string;
	secondaryLabel?: ReactNode;
	isUnpadded?: boolean;
	isTransparent?: boolean;
}>;

const Card = (
	{
		children,
		className,
		isUnpadded,
		isTransparent,
		label,
		secondaryLabel,
	}: CardProps,
	ref: ForwardedRef<HTMLDivElement>
) => {
	return (
		<div
			className={classNames(
				'card',
				isUnpadded && 'card--unpadded',
				isTransparent && 'card--transparent',
				className
			)}
			ref={ref}
		>
			{label && (
				<div className="card__labels">
					<span className="card__label">{label}</span>
					{secondaryLabel}
				</div>
			)}
			<div className="card__content">{children}</div>
		</div>
	);
};

export default forwardRef(Card);
