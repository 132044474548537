import { ENVIRONMENT_SHARED } from 'environment';
import API from 'utils/api/API';
import { AirlineOption } from 'utils/api/AirlinesAPI';
import { FreightForwarderSingleOption } from 'utils/api/FreightForwarderAPI';
import { CityOption, Location } from 'utils/api/common';
import { Paginated } from 'utils/types/common';
import {
	CreateWebToolAircraftModelGroupModel,
	UpdateWebToolAircraftModelGroupModel,
} from '../schemas/webToolAircraftModelGroupSchema';
import {
	CreateWebToolAirlineGroupModel,
	UpdateWebToolAirlineGroupModel,
} from '../schemas/webToolAirlineGroupSchema';
import {
	CreateWebToolBreaksGroupModel,
	UpdateWebToolBreaksGroupModel,
} from '../schemas/webToolBreaksGroupSchema';
import {
	CreateWebToolCityGroupModel,
	UpdateWebToolCityGroupModel,
} from '../schemas/webToolCityGroupSchema';
import {
	CreateWebToolFreightGroupModel,
	UpdateWebToolFreightGroupModel,
} from '../schemas/webToolFreightGroupSchema';
import {
	CreateWebToolGeographyGroupModel,
	UpdateWebToolGeographyGroupModel,
} from '../schemas/webToolGeographyGroupSchema';
import {
	CreateWebToolManufacturerGroupModel,
	UpdateWebToolManufacturerGroupModel,
} from '../schemas/webToolManufacturerGroupSchema';
import {
	CreateWebToolStationGroupModel,
	UpdateWebToolStationGroupModel,
} from '../schemas/webToolStationGroupSchema';
import {
	CreateWebToolTradelaneGroupModel,
	UpdateWebToolTradelaneGroupModel,
} from '../schemas/webToolTradeGroupSchema';
import { AircraftModelSingleOption } from './AircraftModelsAPI';
import { Manufacturer } from './ManufacturersAPI';

export type SaveGroupResponse = {
	id: string;
};

export type WebToolGroupItem = {
	id: string;
	name: string;
	description: string;
	createdAt: Date;
};

export type WebToolAirlineGroupItem = WebToolGroupItem & {
	airlines: AirlineOption[];
	conflicts: ConflictOption[];
};

export type WebToolAircraftModelGroupItem = WebToolGroupItem & {
	aircraftModels: AircraftModelSingleOption[];
	conflicts: ConflictOption[];
};

export type WebToolManufacturerGroupItem = WebToolGroupItem & {
	manufacturers: Manufacturer[];
	conflicts: ConflictOption[];
};

export type SubscriptionAirlinesGroup = {
	label: string;
	options: AirlineOption[];
};

export type WebToolAirlineGroupOptions = {
	airlinesBySubscription: SubscriptionAirlinesGroup[];
};

export type GetAirlineGroupsResponse = {
	groups: Paginated<WebToolAirlineGroupItem>;
};

export type GetAircraftModelGroupResponse = {
	groups: Paginated<WebToolAircraftModelGroupItem>;
};

export type GetManufacturerGroupsResponse = {
	groups: Paginated<WebToolManufacturerGroupItem>;
};

export type WebToolStationGroupItem = WebToolGroupItem & {
	locations: Location[];
	conflicts: ConflictOption[];
};

type GetStationGroupsResponse = {
	groups: Paginated<WebToolStationGroupItem>;
};

export type WebToolCityGroupItem = WebToolGroupItem & {
	cities: CityOption[];
	conflicts: ConflictOption[];
};

type GetCityGroupsResponse = {
	groups: Paginated<WebToolCityGroupItem>;
};

export type WebToolFreightGroupItem = WebToolGroupItem & {
	offices: FreightForwarderSingleOption[];
	conflicts: ConflictOption[];
};

type GetFreightGroupsResponse = {
	groups: Paginated<WebToolFreightGroupItem>;
};

export type WebToolGeographyGroupItem = WebToolGroupItem & {
	locations: Location[];
	conflicts: ConflictOption[];
};

type GetGeographyGroupsResponse = {
	groups: Paginated<WebToolGeographyGroupItem>;
};

export type WebToolBreaksGroupItem = WebToolGroupItem & {
	weightBreaks: number[];
};

export type GetBreaksGroupsResponse = {
	groups: Paginated<WebToolBreaksGroupItem>;
};

export type TradelaneOriginDestinationOption = {
	itemType?: 'tradelane';
	origin: Location;
	destination: Location;
};

export type TradelaneCustomGroupOption = {
	itemType: 'custom-group';
	id: string;
	name: string;
};

export type TradelaneOption =
	| TradelaneOriginDestinationOption
	| TradelaneCustomGroupOption;

export type ConflictOption = {
	id: string;
	name: string;
};

export type WebToolTradelaneGroupItem = WebToolGroupItem & {
	tradeLanes: TradelaneOption[];
	conflicts: ConflictOption[];
};
type GetTradelaneGroupsResponse = {
	groups: Paginated<WebToolTradelaneGroupItem>;
};

class WebToolGroupAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	static getAirlineGroups(params: URLSearchParams) {
		return this.get<GetAirlineGroupsResponse>(
			`/web-tool/airline-group?${params}`
		);
	}

	static getAirlineGroupOptions() {
		return this.get<WebToolAirlineGroupOptions>(
			`/web-tool/airline-group/options`
		);
	}

	static getAirlineGroup(id: string) {
		return this.get<WebToolAirlineGroupItem>(`/web-tool/airline-group/${id}`);
	}

	static createAirlineGroup(data: CreateWebToolAirlineGroupModel) {
		return this.post<SaveGroupResponse>('/web-tool/airline-group', data);
	}

	static updateAirlineGroup(id: string, data: UpdateWebToolAirlineGroupModel) {
		return this.put<SaveGroupResponse>(`/web-tool/airline-group/${id}`, data);
	}

	static deleteAirlineGroup(id: string) {
		return this.delete(`/web-tool/airline-group/${id}`);
	}

	static getAircraftModelGroups(params: URLSearchParams) {
		return this.get<GetAircraftModelGroupResponse>(
			`/web-tool/aircraft-model-group?${params}`
		);
	}

	static getAircraftModelGroup(id: string) {
		return this.get<WebToolAircraftModelGroupItem>(
			`/web-tool/aircraft-model-group/${id}`
		);
	}

	static createAircraftModelGroup(data: CreateWebToolAircraftModelGroupModel) {
		return this.post<SaveGroupResponse>('/web-tool/aircraft-model-group', data);
	}

	static updateAircraftModelGroup(
		id: string,
		data: UpdateWebToolAircraftModelGroupModel
	) {
		return this.put<SaveGroupResponse>(
			`/web-tool/aircraft-model-group/${id}`,
			data
		);
	}

	static deleteAircraftModelGroup(id: string) {
		return this.delete(`/web-tool/aircraft-model-group/${id}`);
	}

	static getManufacturerGroups(params: URLSearchParams) {
		return this.get<GetManufacturerGroupsResponse>(
			`/web-tool/manufacturer-group?${params}`
		);
	}

	static getManufacturerGroup(id: string) {
		return this.get<WebToolManufacturerGroupItem>(
			`/web-tool/manufacturer-group/${id}`
		);
	}

	static createManufacturerGroup(data: CreateWebToolManufacturerGroupModel) {
		return this.post<SaveGroupResponse>('/web-tool/manufacturer-group', data);
	}

	static updateManufacturerGroup(
		id: string,
		data: UpdateWebToolManufacturerGroupModel
	) {
		return this.put<SaveGroupResponse>(
			`/web-tool/manufacturer-group/${id}`,
			data
		);
	}

	static deleteManufacturerGroup(id: string) {
		return this.delete(`/web-tool/manufacturer-group/${id}`);
	}

	static getStationGroups(params: URLSearchParams) {
		return this.get<GetStationGroupsResponse>(
			`/web-tool/station-group?${params}`
		);
	}

	static getStationGroup(id: string) {
		return this.get<WebToolStationGroupItem>(`/web-tool/station-group/${id}`);
	}

	static createStationGroup(data: CreateWebToolStationGroupModel) {
		return this.post<SaveGroupResponse>('/web-tool/station-group', data);
	}

	static updateStationGroup(id: string, data: UpdateWebToolStationGroupModel) {
		return this.put<SaveGroupResponse>(`/web-tool/station-group/${id}`, data);
	}

	static deleteStationGroup(id: string) {
		return this.delete(`/web-tool/station-group/${id}`);
	}

	static getCityGroups(params: URLSearchParams) {
		return this.get<GetCityGroupsResponse>(`/web-tool/city-group?${params}`);
	}

	static getCityGroup(id: string) {
		return this.get<WebToolCityGroupItem>(`/web-tool/city-group/${id}`);
	}

	static createCityGroup(data: CreateWebToolCityGroupModel) {
		return this.post<SaveGroupResponse>('/web-tool/city-group', data);
	}

	static updateCityGroup(id: string, data: UpdateWebToolCityGroupModel) {
		return this.put<SaveGroupResponse>(`/web-tool/city-group/${id}`, data);
	}

	static deleteCityGroup(id: string) {
		return this.delete(`/web-tool/city-group/${id}`);
	}

	static getFreightGroups(params: URLSearchParams) {
		return this.get<GetFreightGroupsResponse>(
			`/web-tool/freight-group?${params}`
		);
	}

	static getFreightGroup(id: string) {
		return this.get<WebToolFreightGroupItem>(`/web-tool/freight-group/${id}`);
	}

	static createFreightGroup(data: CreateWebToolFreightGroupModel) {
		return this.post<SaveGroupResponse>('/web-tool/freight-group', data);
	}

	static updateFreightGroup(id: string, data: UpdateWebToolFreightGroupModel) {
		return this.put<SaveGroupResponse>(`/web-tool/freight-group/${id}`, data);
	}

	static deleteFreightGroup(id: string) {
		return this.delete(`/web-tool/freight-group/${id}`);
	}

	static getGeographyGroups(params: URLSearchParams) {
		return this.get<GetGeographyGroupsResponse>(
			`/web-tool/geography-group?${params}`
		);
	}

	static getGeographyGroup(id: string) {
		return this.get<WebToolGeographyGroupItem>(
			`/web-tool/geography-group/${id}`
		);
	}

	static createGeographyGroup(data: CreateWebToolGeographyGroupModel) {
		return this.post<SaveGroupResponse>('/web-tool/geography-group', data);
	}

	static updateGeographyGroup(
		id: string,
		data: UpdateWebToolGeographyGroupModel
	) {
		return this.put<SaveGroupResponse>(`/web-tool/geography-group/${id}`, data);
	}

	static deleteGeographyGroup(id: string) {
		return this.delete(`/web-tool/geography-group/${id}`);
	}

	static getBreaksGroups(params?: URLSearchParams) {
		return this.get<GetBreaksGroupsResponse>(
			`/web-tool/breaks-group?${params}`
		);
	}

	static getBreaksGroup(id: string) {
		return this.get<WebToolBreaksGroupItem>(`/web-tool/breaks-group/${id}`);
	}

	static createBreaksGroup(data: CreateWebToolBreaksGroupModel) {
		return this.post<SaveGroupResponse>('/web-tool/breaks-group', data);
	}

	static updateBreaksGroup(id: string, data: UpdateWebToolBreaksGroupModel) {
		return this.put<SaveGroupResponse>(`/web-tool/breaks-group/${id}`, data);
	}

	static deleteBreaksGroup(id: string) {
		return this.delete(`/web-tool/breaks-group/${id}`);
	}

	static getTradelaneGroups(params: URLSearchParams) {
		return this.get<GetTradelaneGroupsResponse>(
			`/web-tool/trade-lane-group?${params}`
		);
	}

	static getTradelaneGroup(id: string) {
		return this.get<WebToolTradelaneGroupItem>(
			`/web-tool/trade-lane-group/${id}`
		);
	}

	static createTradelaneGroup(data: CreateWebToolTradelaneGroupModel) {
		return this.post<SaveGroupResponse>('/web-tool/trade-lane-group', data);
	}

	static updateTradelaneGroup(
		id: string,
		data: UpdateWebToolTradelaneGroupModel
	) {
		return this.put<SaveGroupResponse>(
			`/web-tool/trade-lane-group/${id}`,
			data
		);
	}

	static deleteTradeGroup(id: string) {
		return this.delete(`/web-tool/trade-lane-group/${id}`);
	}
}

export default WebToolGroupAPI;
