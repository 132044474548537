import { shift } from '@floating-ui/react';
import { format } from 'date-fns';
import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { useMemo, useState } from 'react';
import ReactDatepicker, { ReactDatePickerProps } from 'react-datepicker';
import { Option } from 'utils/types/common';
import useFormReset from '../ValidatedForm/useFormReset';
import DatePickerHeader from './DatePickerHeader';

export const ISO_FORMAT = "yyyy-MM-dd'T'HH:mm:ss'Z'";

export const POPPER_MODIFIER: ReactDatePickerProps['popperModifiers'] = [
	{
		name: 'offset',
		options: {
			offset: [0, 5],
		},
		fn: (data) => {
			return {
				...data,
				y: data.y - 5,
			};
		},
	},
	shift(),
];

export type DatePickerProps = {
	monthCount?: number;
	name?: string;
	onChange?: (date: Date | null) => void;
	value?: Date | null;
	initialValue?: Option<Date>;
	id?: string;
	placeholder?: string;
	minDate?: Date;
	maxDate?: Date;
	dateFormat?: string;
	isClearable?: boolean;
	timezone?: 'utc' | 'local';
	includeTime?: boolean;
	customInput?: React.ReactNode;
};

const DatePicker = ({
	monthCount = 1,
	name,
	onChange,
	id,
	initialValue,
	value: propsSelectedDate,
	placeholder,
	minDate,
	maxDate,
	dateFormat = 'dd MMM yyyy',
	isClearable,
	timezone = 'local',
	includeTime,
	customInput,
}: DatePickerProps) => {
	const [selectedDate, setSelectedDate] = useState<Date | null>(
		initialValue ?? null
	);

	const formattedValue = useMemo(() => {
		const date = propsSelectedDate ?? selectedDate;
		if (!date) return null;

		if (timezone === 'local') {
			return format(utcToZonedTime(date, 'UTC'), ISO_FORMAT);
		} else {
			return formatInTimeZone(date, 'UTC', ISO_FORMAT);
		}
	}, [selectedDate, timezone]);

	useFormReset(() => {
		setSelectedDate(null);
	});

	const timezoneCorrectedDate = useMemo(() => {
		const date = propsSelectedDate ?? selectedDate;
		if (!date) return null;

		if (timezone === 'local') {
			return date;
		} else {
			// Create a local date that references the selected date UTC time as local time
			return utcToZonedTime(date, 'UTC');
		}
	}, [selectedDate, propsSelectedDate, timezone]);

	return (
		<>
			<ReactDatepicker
				selected={timezoneCorrectedDate}
				onChange={(date) => {
					let timezoneAwareDate = date;

					if (timezone === 'utc' && date) {
						// Create a date that references the selected local time as UTC
						timezoneAwareDate = zonedTimeToUtc(date, 'UTC');
					}

					setSelectedDate(timezoneAwareDate);
					onChange?.(timezoneAwareDate);
				}}
				customInput={customInput ?? <input className="control__input" />}
				id={id}
				renderCustomHeader={DatePickerHeader}
				monthsShown={monthCount}
				dateFormat={includeTime ? `${dateFormat} HH:mm` : dateFormat}
				placeholderText={placeholder}
				minDate={minDate}
				maxDate={maxDate}
				isClearable={isClearable}
				showTimeSelect={includeTime}
				popperModifiers={POPPER_MODIFIER}
				timeFormat="HH:mm"
			/>
			{formattedValue && name && (
				<input type="hidden" name={name} value={formattedValue} />
			)}
		</>
	);
};

export default DatePicker;
