import Pill from 'ui/components/Pill';
import { AircraftModel } from 'utils/api/AircraftModelsAPI';

type AircraftModelPickerMultiValuePillProps = {
	value: AircraftModel;
	onRemove: () => void;
};

const AircraftModelPickerMultiValuePill = ({
	value,
	onRemove,
}: AircraftModelPickerMultiValuePillProps) => {
	if (value.itemType === 'custom-group') {
		return <Pill name={value.name} onRemove={onRemove} />;
	}

	const code =
		value.iataCode === value.icaoCode
			? value.iataCode
			: `${value.iataCode} / ${value.icaoCode}`;

	const name = value.name || 'Unknown';

	return <Pill code={code ?? undefined} name={name} onRemove={onRemove} />;
};

export default AircraftModelPickerMultiValuePill;
