import Pill from 'ui/components/Pill';
import { AircraftModel } from 'utils/api/AircraftModelsAPI';

type AircraftModelPickerPreviewProps = {
	aircraftModel: AircraftModel;
};

const AircraftModelPickerPreview = ({
	aircraftModel,
}: AircraftModelPickerPreviewProps) => {
	if (aircraftModel.itemType === 'custom-group') {
		return <>{aircraftModel.name}</>;
	}

	const code =
		aircraftModel.iataCode === aircraftModel.icaoCode
			? aircraftModel.iataCode
			: `${aircraftModel.iataCode} / ${aircraftModel.icaoCode}`;

	return (
		<Pill code={code ?? undefined} name={aircraftModel.name ?? 'Unknown'} />
	);
};

export default AircraftModelPickerPreview;
