import classNames from 'classnames';
import { ChevronUp } from 'lucide-react';
import React, {
	CSSProperties,
	PropsWithChildren,
	useEffect,
	useRef,
	useState,
} from 'react';
import Card from 'ui/components/Card/Card';
import CardHeader from 'ui/components/Card/CardHeader';
import Toggle from 'ui/components/Toggle/Toggle';

type WorksheetSectionProps = PropsWithChildren<{
	title: string;
	defaultCollapsed?: boolean;
	style?: React.CSSProperties;

	isOpen?: boolean;
	onOpenChange?: (open: boolean) => void;

	isHighlighted?: boolean;

	advancedModeEnabled?: boolean;
	onAdvancedModeEnabledChange?: (enabled: boolean) => void;
}>;

const WorksheetSection = ({
	title,
	defaultCollapsed = false,
	children,
	style = {},

	isHighlighted = false,

	isOpen: controlledOpen,
	onOpenChange: onControlledOpenChange,

	advancedModeEnabled,
	onAdvancedModeEnabledChange,
}: WorksheetSectionProps) => {
	const [uncontrolledOpen, setUncontrolledOpen] = useState(!defaultCollapsed);
	const isOpen = controlledOpen ?? uncontrolledOpen;
	const onOpenChange = onControlledOpenChange ?? setUncontrolledOpen;

	const nonNullChildCount =
		React.Children.toArray(children).filter(Boolean).length;

	const mergedStyle = {
		'--parameters-grid': `repeat(${nonNullChildCount}, 1fr)`,
		...style,
	} as CSSProperties;

	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (isHighlighted && ref.current) {
			ref.current.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
		}
	}, [isHighlighted]);

	return (
		<Card
			className={classNames(
				'worksheet__section section',
				isHighlighted && 'section--highlighted',
				!isOpen && 'section--collapsed'
			)}
			isUnpadded
			ref={ref}
		>
			<CardHeader>
				<button
					type="button"
					className="section__title"
					onClick={() => onOpenChange(!isOpen)}
				>
					<ChevronUp />
					{title}
				</button>
				{advancedModeEnabled !== undefined && (
					<label className="section__advanced">
						<Toggle
							size="small"
							isChecked={advancedModeEnabled ?? false}
							onChange={(e) => onAdvancedModeEnabledChange?.(e.target.checked)}
						/>
						Advanced Options
					</label>
				)}
			</CardHeader>

			<div
				className={classNames('section__grid', isOpen && 'section__grid--open')}
				style={mergedStyle}
			>
				{React.Children.map(children, (child) => {
					return child ? <div className="section__column">{child}</div> : null;
				})}
			</div>
		</Card>
	);
};

export default WorksheetSection;
