import classNames from 'classnames';
import { useMemo } from 'react';
import PillSelection, {
	PillSelectionOption,
} from 'ui/components/PillSelection/PillSelection';
import Tooltip from 'ui/components/Tooltip';
import VisuallyHidden from 'ui/components/VisuallyHidden/VisuallyHidden';
import {
	WORKSHEET_DATA_FIELD_DIMENSIONS,
	WORKSHEET_DATA_FIELD_DIMENSIONS_KEYS,
	WORKSHEET_DATA_FIELD_DIMENSIONS_SHORT,
	WorksheetConfigDataField,
	WorksheetDataFieldState,
} from 'utils/api/WebToolAPI';
import { useWorksheetContext } from '../WorksheetContext';

type WorksheetDataPickerRowProps = {
	dataField: WorksheetConfigDataField;
	isAdvanced: boolean;
	selectionState: WorksheetDataFieldState;
	onSelectionStateChange: (state: WorksheetDataFieldState) => void;
	availableColumns: readonly [boolean, boolean, boolean];
};

const WorksheetDataPickerRow = ({
	dataField,
	selectionState,
	isAdvanced,
	onSelectionStateChange,
	availableColumns,
}: WorksheetDataPickerRowProps) => {
	const { state, derivedState } = useWorksheetContext();

	const {
		isCustomer = true,
		isMarket = true,
		isCustomerShare = true,
	} = dataField;

	const columnsActive = [isCustomer, isMarket, isCustomerShare];
	const columnsDisabled = derivedState.disabledDataFields[dataField.id] ?? [
		false,
		false,
		false,
	];
	const columnNames = ['Customer', 'Market', 'Customer Share'];
	const selectionStateKeys = ['customer', 'market', 'customerShare'] as const;

	const getOptionsForColumn = (
		columnIndex: number
	): PillSelectionOption<number>[] => {
		const columnName = columnNames[columnIndex];

		if (!isAdvanced) {
			return [
				{
					id: -1,
					label: columnName,
				},
			];
		}

		const options = WORKSHEET_DATA_FIELD_DIMENSIONS.map(
			(dimensionName, dimensionIndex) => {
				return {
					id: dimensionIndex,
					label: WORKSHEET_DATA_FIELD_DIMENSIONS_SHORT[dimensionIndex],
					hint: dimensionName,
				};
			}
		);

		const activeOptions = options.filter(
			(_, index) => state.output.dataFieldTimeSeries.advanced[index]
		) as PillSelectionOption<number>[];

		return activeOptions;
	};

	const getSelectedOptionsForColumn = (
		columnIndex: number
	): number[] | undefined => {
		const selectionStateKey = selectionStateKeys[columnIndex];
		const columnSelection = selectionState[selectionStateKey];

		if (!isAdvanced) {
			return columnSelection.simple ? [-1] : undefined;
		}

		// Return the index of all selected options
		return columnSelection.advanced
			.map((val, index) => (val ? index : undefined))
			.filter((val): val is number => val !== undefined);
	};

	// Takes the single dimension (Customer / Market / Customer Share) and returns options for the TY / LY / YOY / YOYC
	const getDisabledOptionsForColumn = (
		columnIndex: number
	): number[] | undefined => {
		if (columnsDisabled[columnIndex]) {
			if (!isAdvanced) {
				return [-1];
			}

			return WORKSHEET_DATA_FIELD_DIMENSIONS.map((_, index) => index);
		}

		// Check disabled metric types
		const disabledMetricIndices = derivedState.disabledDataFieldMetrics.map(
			(metric) => WORKSHEET_DATA_FIELD_DIMENSIONS_KEYS.indexOf(metric)
		);

		if (disabledMetricIndices.length > 0) {
			return disabledMetricIndices;
		}

		return undefined;
	};

	const handleOptionSelected = (columnIndex: number, option: number) => {
		const selectionStateKey = selectionStateKeys[columnIndex];

		const newSelectionStateValue = structuredClone(
			selectionState[selectionStateKey]
		);

		if (option === -1) {
			newSelectionStateValue.simple = !newSelectionStateValue.simple;
		} else {
			newSelectionStateValue.advanced[option] =
				!newSelectionStateValue.advanced[option];
		}

		const newSelectionState = {
			...selectionState,
			[selectionStateKey]: newSelectionStateValue,
		};

		onSelectionStateChange(newSelectionState);
	};

	const isSelected = useMemo(() => {
		const selectionValues = Object.values(selectionState);

		return selectionValues.some((columnSelection) => {
			if (isAdvanced) {
				return Object.values(columnSelection.advanced).some(
					(value, valueIndex) => {
						return (
							value && state.output.dataFieldTimeSeries.advanced[valueIndex]
						);
					}
				);
			} else {
				return columnSelection.simple;
			}
		});
	}, [selectionState, isAdvanced, state.output.dataFieldTimeSeries.advanced]);

	return (
		<div
			className={classNames(
				'data__row',
				isAdvanced && 'data__row--advanced',
				isSelected && 'data__row--selected'
			)}
		>
			<Tooltip disabled={!dataField.tooltip} delay={300}>
				<Tooltip.Trigger>
					<span>{dataField.label}</span>
				</Tooltip.Trigger>
				<Tooltip.Content size="large">{dataField.tooltip}</Tooltip.Content>
			</Tooltip>
			{columnsActive.map(
				(columnActive, columnIndex) =>
					availableColumns[columnIndex] && (
						<VisuallyHidden key={columnIndex} isHidden={!columnActive}>
							<PillSelection
								options={getOptionsForColumn(columnIndex)}
								selectedOptions={getSelectedOptionsForColumn(columnIndex)}
								disabledOptions={getDisabledOptionsForColumn(columnIndex)}
								onOptionSelected={(option) =>
									handleOptionSelected(columnIndex, option)
								}
							/>
						</VisuallyHidden>
					)
			)}
		</div>
	);
};

export default WorksheetDataPickerRow;
