/* eslint-disable @typescript-eslint/naming-convention */
// Zod's parameters are snake_case, but we only allow camelCase
// Disable the rule for this file
import * as objectPath from 'object-path-immutable';
import z, { SuperRefinement } from 'zod';
import { YearAndMonth } from '../types/helpers';

export const zMongoId = z.string().regex(/^[0-9a-f]{24}$/i);

export const zNumberRequiredBetween = (
	requiredError: string,
	min: number,
	max: number
) => z.number({ required_error: requiredError }).min(min).max(max);

export const zArrayOfMinStringsRequired = (
	requiredMessage: string,
	minRequired: number
) =>
	z
		.array(z.string(), { required_error: requiredMessage })
		.nonempty({ message: requiredMessage })
		.min(minRequired, { message: requiredMessage });
export const zArrayOfStringRequired = (requiredMessage: string) =>
	z
		.array(z.string(), { required_error: requiredMessage })
		.nonempty({ message: requiredMessage });

export const zArrayOfNumberRequired = (requiredMessage: string) =>
	z
		.array(z.number(), { required_error: requiredMessage })
		.nonempty({ message: requiredMessage });

export const zArrayOfStringOptional = () => z.array(z.string()).optional();

export const zNumberPreprocessor = (input: unknown) => {
	const processed = z
		.string()
		.regex(/^\d+$/)
		.transform(Number)
		.safeParse(input);
	return processed.success ? processed.data : undefined;
};

export const zEmptyAsNumberPreprocessor = (input: unknown) => {
	if (!input) return undefined;

	const processed = z
		.string()
		.regex(/^\d+$/)
		.transform(Number)
		.safeParse(input);
	return processed.success ? processed.data : input;
};

export const zEnsureArrayPreprocessor = (input: unknown) => {
	// When input is undefined, return nothing
	if (input === undefined) {
		return undefined;
	}

	if (Array.isArray(input)) {
		return input;
	}

	return [input];
};

export const zBoolPreprocessor = (input: unknown) => {
	if (typeof input === 'string') {
		return /true/i.test(input);
	}

	return false;
};

export const zMonthPickerRefinement: <T extends Record<string, unknown>>(
	startPeriodPath: string,
	endPeriodPath: string
) => SuperRefinement<T> = (startPeriodPath, endPeriodPath) => (data, ctx) => {
	const startPeriod: YearAndMonth | undefined = objectPath.get(
		data,
		startPeriodPath
	);
	const endPeriod: YearAndMonth | undefined = objectPath.get(
		data,
		endPeriodPath
	);

	if (startPeriod === undefined || endPeriod === undefined) {
		return;
	}

	if (
		startPeriod.year > endPeriod.year ||
		(startPeriod.year === endPeriod.year && startPeriod.month > endPeriod.month)
	) {
		ctx.addIssue({
			code: z.ZodIssueCode.custom,
			path: ['startPeriod'],
			message: 'Start Period must be the same or before the End Period',
			fatal: true,
		});
	}
};

export const zFilesRequiredRefinement =
	(requiredError: string) =>
	(fileOrFiles: File | File[], context: z.RefinementCtx) => {
		let fileExists = false;

		if (Array.isArray(fileOrFiles)) {
			fileExists = fileOrFiles.length > 0;
		} else {
			fileExists = fileOrFiles.size !== 0;
		}

		if (!fileExists) {
			context.addIssue({
				code: z.ZodIssueCode.custom,
				message: requiredError,
				fatal: true,
			});

			return z.NEVER;
		}

		return true;
	};

export const zFilesMaxSizeRefinement =
	(maxSize: number) => (fileOrFiles: File | File[]) => {
		const files = Array.isArray(fileOrFiles) ? fileOrFiles : [fileOrFiles];
		return files.every((file) => file.size <= maxSize);
	};

export const zFilesTypesRefinement =
	(allowedTypes: string[]) => (fileOrFiles: File | File[]) => {
		if (fileOrFiles instanceof File && fileOrFiles.size === 0) return true;
		const files = Array.isArray(fileOrFiles) ? fileOrFiles : [fileOrFiles];
		return files.every((file) => allowedTypes.includes(file.type));
	};

export const zFilesExtensionsRefinement =
	(allowedExtensions: string[]) => (fileOrFiles: File | File[]) => {
		const files = Array.isArray(fileOrFiles) ? fileOrFiles : [fileOrFiles];
		return files.every((file) =>
			allowedExtensions.some((extension) => file.name.endsWith(extension))
		);
	};

export const zAuditable = z.object({
	auditComment: z
		.string()
		.min(7, 'Audit comment must be at least 7 characters')
		.max(1000, 'Audit comment must not be more than 1000 characters'),
});

export const zEmptyObject = z.object({});

export const zDatePreprocessor = (input: unknown) => {
	if (typeof input === 'string' || input instanceof Date) {
		const date = new Date(input);
		if (isNaN(date.getTime())) return null;
		return date;
	} else return input;
};
