import { useMemo } from 'react';
import AsyncMultiSelect from 'ui/components/AsyncMultiSelect';
import AsyncSelect from 'ui/components/AsyncSelect';
import FormField from 'ui/components/FormField';
import useValidation from 'ui/components/ValidatedForm/useValidation';
import AircraftModelsAPI, {
	AircraftModel,
	AircraftModelCustomGroupOption,
	AircraftModelSingleOption,
	AircraftModelType,
} from 'utils/api/AircraftModelsAPI';
import { WorksheetConfigWebToolGroup } from 'utils/api/WebToolAPI';
import { catchSilently } from 'utils/helpers/catchHandlers';
import {
	createValidationToast,
	parseTextToCodes,
} from 'utils/helpers/codeValidation';
import { createToast } from 'utils/helpers/toast';
import { OptionGroup } from '../Dropdown/types';
import { ToastType } from '../Toaster/Toast';
import AircraftModelPickerContent from './AircraftModelPickerContent';
import AircraftModelPickerMultiValuePill from './AircraftModelPickerMultiValuePill';
import AircraftModelPickerPreview from './AircraftModelPickerPreview';

export const customGroupToAircraftModelOption = (
	customGroup: WorksheetConfigWebToolGroup
): AircraftModelCustomGroupOption => ({
	name: customGroup.name,
	itemType: 'custom-group',
	code: customGroup.id,
});

type SingleAircraftModelPickerProps = {
	isMulti?: false;
	initialValue?: AircraftModel;
	initialValues?: never;
	onSelectedAircraftModelChange?: (value: AircraftModel | null) => void;
	onSelectedAircraftModelsChange?: never;
	selectedAircraftModel?: AircraftModel | null;
	selectedAircraftModels?: never;
};

type MultiAircraftModelPickerProps = {
	isMulti: true;
	initialValue?: never;
	initialValues?: AircraftModel[];
	onSelectedAircraftModelChange?: never;
	onSelectedAircraftModelsChange?: (values: AircraftModel[]) => void;
	selectedAircraftModel?: never;
	selectedAircraftModels?: AircraftModel[];
};

export type AircraftModelPickerProps = {
	name: string;
	label?: string;
	isRequired?: boolean;
	isDisabled?: boolean;
	aircraftModelTypes?: AircraftModelType[];
	aircraftModelCustomGroups?: WorksheetConfigWebToolGroup[];
} & (SingleAircraftModelPickerProps | MultiAircraftModelPickerProps);

const AircraftModelPicker = (props: AircraftModelPickerProps) => {
	const {
		name,
		label,
		isRequired,
		isDisabled,
		aircraftModelTypes = ['aircraft-model'],
		aircraftModelCustomGroups,
	} = props;

	const { errorTree } = useValidation(name);
	const isGrouped = aircraftModelTypes.length > 1;

	const searchAircraftModels = async (searchTerm: string) => {
		const data = await AircraftModelsAPI.getAircraftModels(
			searchTerm,
			aircraftModelTypes ?? ['aircraft-model']
		);
		return data.options || data.groups;
	};

	const defaultOptions = useMemo(():
		| AircraftModel[]
		| OptionGroup<AircraftModel>[]
		| undefined => {
		if (!aircraftModelCustomGroups || aircraftModelCustomGroups.length === 0) {
			return undefined;
		}

		const options = aircraftModelCustomGroups.map(
			customGroupToAircraftModelOption
		);

		if (!isGrouped) {
			return options;
		}

		return [
			{
				label: 'Custom Groupings',
				options,
			},
		];
	}, [aircraftModelCustomGroups]);

	const handleInsert = async (text: string) => {
		const codes = parseTextToCodes(text);

		if (codes) {
			const validatePromise =
				AircraftModelsAPI.validateAircraftModelCodes(codes);

			createValidationToast(validatePromise, 'aircraft model').catch(
				catchSilently
			);

			const validationResult = await validatePromise;
			return validationResult.options;
		}

		return false;
	};

	const handleCopy = async (
		aircraftModels: AircraftModel[] | AircraftModel | null
	) => {
		let value = '';
		let hasCustomGroups = false;

		if (Array.isArray(aircraftModels)) {
			if (
				aircraftModels.some(
					(aircraftModel) => aircraftModel.itemType === 'custom-group'
				)
			) {
				hasCustomGroups = true;
			}

			value = aircraftModels
				.filter(
					(aircraftModel): aircraftModel is AircraftModelSingleOption =>
						aircraftModel.itemType !== 'custom-group'
				)
				.map((aircraftModel) => aircraftModel.code)
				.join(', ');
		} else if (aircraftModels) {
			if (aircraftModels.itemType === 'custom-group') {
				hasCustomGroups = true;
			} else if ('code' in aircraftModels) {
				value = aircraftModels.code;
			}
		}

		if (value) {
			navigator.clipboard.writeText(value);
		}

		if (hasCustomGroups) {
			createToast(ToastType.WARNING, {
				message: 'Custom groups omitted',
				hint: 'The selected custom groups have been omitted. They must be manually selected from the dropdown.',
			});
		}
	};

	return (
		<FormField
			label={label}
			errors={errorTree?._errors}
			isRequired={isRequired}
		>
			{props.isMulti ? (
				<AsyncMultiSelect<AircraftModel>
					isGrouped={isGrouped}
					isDisabled={isDisabled}
					onSearch={searchAircraftModels}
					identifierKey="code"
					name={name}
					onCopy={handleCopy}
					handlePasteSelection={handleInsert}
					placeholder="Search aircraft models..."
					selectedOptions={props.selectedAircraftModels}
					onSelectedOptionsChange={props.onSelectedAircraftModelsChange}
					contentSource={(o) => (
						<AircraftModelPickerContent aircraftModel={o} />
					)}
					initialValues={props.initialValues}
					pillComponent={AircraftModelPickerMultiValuePill}
					defaultOptions={defaultOptions}
				/>
			) : (
				<AsyncSelect<AircraftModel>
					isGrouped={isGrouped}
					isDisabled={isDisabled}
					onSearch={searchAircraftModels}
					identifierKey="code"
					name={name}
					onCopy={handleCopy}
					handlePasteSelection={handleInsert}
					placeholder="Select aircraft model..."
					contentSource={(o) => (
						<AircraftModelPickerContent aircraftModel={o} />
					)}
					isClearable={true}
					onOptionSelected={props.onSelectedAircraftModelChange}
					selectedOption={props.selectedAircraftModel}
					initialValue={props.initialValue}
					previewSource={(o) => (
						<AircraftModelPickerPreview aircraftModel={o} />
					)}
					defaultOptions={defaultOptions}
				/>
			)}
		</FormField>
	);
};

export default AircraftModelPicker;
