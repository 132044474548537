import { FormEventHandler, useCallback, useState } from 'react';
import BackToTop from 'ui/components/BackToTop';
import ValidatedForm from 'ui/components/ValidatedForm/ValidatedForm';
import {
	WorksheetState,
	Worksheet as WorksheetType,
} from 'utils/api/WebToolAPI';
import { WebToolBreaksGroupItem } from 'utils/api/WebToolGroupAPI';
import { getDefaultDataFields } from './DataPicker/WorksheetDataPicker';
import { WorksheetProvider } from './WorksheetContext';
import WorksheetHeader from './WorksheetHeader';
import WorksheetLayout from './WorksheetLayout';
import WorksheetNavigationConfirmation from './WorksheetNavigationConfirmation';
import WorksheetOptionsModal from './WorksheetOptionsModal';
import WorksheetOutput from './WorksheetOutput';
import WorksheetParameters from './WorksheetParameters';
import WorksheetPreview from './WorksheetPreview';
import WorksheetShareModal from './WorksheetShareModal';
import WorksheetToolbar from './WorksheetToolbar';

type WorksheetProps = {
	worksheet: WorksheetType;
	weightBreakGroups: WebToolBreaksGroupItem[];
};

const Worksheet = ({ worksheet, weightBreakGroups }: WorksheetProps) => {
	const [state, setState] = useState<WorksheetState>(worksheet.initialState);

	const handleReset = useCallback<FormEventHandler<HTMLFormElement>>(
		(e) => {
			e.preventDefault();
			const defaultFieldOptionGroups: { [key: string]: string[] } = {};
			for (const optionGroup of worksheet.config.optionGroups) {
				defaultFieldOptionGroups[optionGroup.id] = optionGroup.options
					.filter((option) => option.defaultSelected)
					.map((option) => option.id);
			}

			const defaultWebToolGroups: { [key: string]: string[] } = {};
			for (const field of worksheet.config.fieldGroups.flatMap((fg) =>
				fg.fields.filter((field) => field.groupType)
			)) {
				const groupsForField = worksheet.config.webToolGroups
					.filter((group) => group.groupType === field.groupType)
					.map((g) => g.id);

				// Set default group if only one group is available, otherwise the user should select which groups to use
				if (groupsForField.length === 1) {
					defaultWebToolGroups[field.id] = groupsForField;
				}
			}

			setState((state) => ({
				...state,
				parameters: {
					...state.parameters,
					location: {
						...state.parameters.location,
						tradelanes: [],
						includeReverseLocations: false,
						releasedCountriesOnly: false,
					},
					awb: {
						...state.parameters.awb,
						airlinePrefix: '',
						serialNumber: '',
					},
					breakdown: {
						...state.parameters.breakdown,
						airlineTypeOfOperations: [],
						aircraftModels: [],
						capacityTypes: [],
						specialHandlingCodes: '',
					},
					advancedMode: false,
				},
				output: {
					...state.output,
					advancedMode: false,
					dataFieldTimeSeries: {
						advanced: [true, false, false, false, false, false],
						simple: [true, false, false, false, false, false],
					},
					fields: [],
					dataFields: getDefaultDataFields(worksheet.config),
					fieldOptionGroups: defaultFieldOptionGroups,
					webToolGroups: defaultWebToolGroups,
					distortion: worksheet.config.distortionOptions
						.filter((o) => o.defaultSelected)
						.map((o) => o.id),
				},
				preview: {
					sorting: [],
				},
			}));
		},
		[worksheet, state]
	);

	const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>((e) => {
		e.preventDefault();
	}, []);

	return (
		<WorksheetProvider
			worksheet={worksheet}
			state={state}
			weightBreakGroups={weightBreakGroups}
			onStateChange={setState}
		>
			<ValidatedForm
				className="worksheet"
				method="put"
				onReset={handleReset}
				onSubmit={handleSubmit}
			>
				<WorksheetHeader />
				<WorksheetParameters />
				<WorksheetOutput />
				<WorksheetOptionsModal />
				<WorksheetLayout />
				<WorksheetToolbar showPreviewSummary />
				<WorksheetPreview />
				<WorksheetNavigationConfirmation />
				<WorksheetShareModal />
			</ValidatedForm>
			<BackToTop minScrollOffset={100} />
		</WorksheetProvider>
	);
};

export default Worksheet;
