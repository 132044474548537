import {
	AdminEnvironmentSettings,
	ClientPortalEnvironmentSettings,
	SharedEnvironmentSettings,
} from './environment-types';

export const ENVIRONMENT_CLIENT_PORTAL: ClientPortalEnvironmentSettings = {
	msal: {
		clientId: '74f98ece-2473-44b0-b57f-1e9332a85f10',
		authority: 'https://iataazureprodb2c.b2clogin.com/iataazureprodb2c.onmicrosoft.com/b2c_1a_signup_signin',
		redirectUri: '/logged-in',
		knownAuthority: 'https://iataazureprodb2c.b2clogin.com',
		scope: 'https://iataazureprodb2c.onmicrosoft.com/cargois/App.User',
	},
};

export const ENVIRONMENT_ADMIN: AdminEnvironmentSettings = {
	msal: {
		clientId: 'b51fc105-5b43-400c-a3a0-cdcb906aca8d',
		authority: 'https://iataazureprodb2c.b2clogin.com/iataazureprodb2c.onmicrosoft.com/b2c_1a_signup_signin',
		redirectUri: '/logged-in',
		knownAuthority: 'https://iataazureprodb2c.b2clogin.com',
		scope: 'https://iataazureprodb2c.onmicrosoft.com/cargois/App.Admin',
	},
	staticReportEndpoint: 'https://api.cargois.iata.org/static-reports',
	emailServiceEndpoint: 'https://api.cargois.iata.org/email',
};

export const ENVIRONMENT_SHARED: SharedEnvironmentSettings = {
	subscriptionsApiEndpoint: 'https://api.cargois.iata.org',
	termsAndConditionsUrl:
		'https://portal.iata.org/s/article/cargois-Terms-and-Conditions',
	knowledgeHubUrl:
		'https://portal.iata.org/s/support-view-category?category=Data_Products&topic=CargoIS',
	contactUsUrl: 'https://portal.iata.org/s/support-reach-us?topic=CargoIS',
	portalUrl: 'https://cargois.iata.org',
	featureFlags: {
		enableWebTool: Boolean('true'),
	},
	msal: {
		enableLogging: false,
	},
	tinyMce: {
		apiKey: 'korwm7hnjofh1o9q9649sdfzh7rhpth5rgn577t5iywfr1by',
	},
};
